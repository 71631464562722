import keys from 'lodash/keys'
import reverse from 'lodash/reverse'
import isEmpty from 'lodash/isEmpty'

const hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

const hexToRgb2 = (hex, opacity = 1) => {
  const rgbArr = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))
  return `rgba(${rgbArr[0]}, ${rgbArr[1]}, ${rgbArr[2]}, ${opacity})`
}

const isScrollable = (node) => {
  const style = window.getComputedStyle(node)
  const overflowDirec = style.getPropertyValue('overflow-y')
  const overflow = overflowDirec || style.getPropertyValue('overflow')
  return (overflow === 'auto' || overflow === 'scroll' || overflow === 'overlay')
}

const findScrollable = (ref, searchChildren, maxDepth = 2, isInitial = true) => {
  let node = ref
  if (searchChildren) {
    let curr = false
    for (let child of node.children) {
      if (isScrollable(child)) {
        return child
      } else if (maxDepth > 0) {
        curr = findScrollable(child, searchChildren, maxDepth - 1, false)
      }
      if (curr) return curr
    }
    if (isInitial && curr) return curr
    return false
  } else {
    while (node && node.parentNode) {
      node = node.parentNode
      if (node === document.body) {
        // We've reached all the way to the root node.
        const fallback = document.getElementById('root_scrollContainer')
        return fallback || window
      }
      if (isScrollable(node)) {
        return node
      }
    }
  }
  // A scrollable ancestor element was not found, which means that we need to
  // do stuff on window.
  return window
}

// scrollTo method scrolls scrollable element `node` to the target node.
// If target node is not provided, scrollable element is scrolled to the end/bottom.
const scrollTo = (node, duration, targetNode, top) => new Promise((resolve, reject) => {
  try {
    let start = node.scrollTop
    let end = top ? 0 : targetNode ? targetNode.offsetTop : node.scrollHeight
    let change = end - start
    let increment = 20
    const easeInOut = (currentTime, start, change, duration) => {
      currentTime /= duration / 2
      if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start
      }
      currentTime -= 1
      return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
    }
    const animate = (elapsedTime) => {
      elapsedTime += increment
      node.scrollTop = easeInOut(elapsedTime, start, change, duration)
      if (elapsedTime < duration) {
        setTimeout(() => {
          animate(elapsedTime)
        }, increment)
      } else {
        resolve()
      }
    }

    animate(0)
  } catch (e) {
    reject(e)
  }
})

const findStickyChildren = (start, end) => {
  let stickies = []
  if (start === end) return stickies
  for (let child of start.children) {
    if (child === end) return stickies
    const style = window.getComputedStyle(child)
    const positionDirec = style.getPropertyValue('position')
    const position = positionDirec || style.getPropertyValue('position')
    if ((position === 'sticky') || (position === 'fixed')) {
      stickies = [...stickies, child]
    } else if (!isEmpty(child.children)) {
      stickies = [...stickies, ...findStickyChildren(child, end)]
    }
  }
  return stickies
}

let elementsWithListeners = []
let registeredListeners = []

const addListener = (el, event, cb) => {
  let idx = elementsWithListeners.indexOf(el)
  if (idx === -1) {
    idx = elementsWithListeners.length
    elementsWithListeners.push(el)
    registeredListeners.push({ el: el, totalCount: 0 })
  }

  let listeners = registeredListeners[idx]
  let listener = listeners[event]

  if (!listener) {
    listener = listeners[event] = { callbacks: [] }
    listener.cb = e => {
      for (let i = 0, l = listener.callbacks.length; i < l; i += 1) {
        listener.callbacks[i](e)
      }
    }
    listeners.totalCount += 1
    listeners.el.addEventListener(event, listener.cb)
  }

  // just to prevent double listeners
  if (listener.callbacks.indexOf(cb) !== -1) {
    return
  }

  listener.callbacks.push(cb)
}

const removeListener = (el, event, cb) => {
  let idx = elementsWithListeners.indexOf(el)
  if (idx === -1) {
    return
  }

  let listeners = registeredListeners[idx]
  let listener = listeners[event]
  let callbacks = listener ? listener.callbacks : []

  if (!listener || callbacks.indexOf(cb) === -1) {
    return
  }

  callbacks.splice(callbacks.indexOf(cb), 1)
  if (callbacks.length > 0) {
    return
  }

  listeners.el.removeEventListener(event, listener.cb)
  listeners.totalCount -= 1
  delete listeners[event]

  if (listeners.totalCount > 0) {
    return
  }

  elementsWithListeners.splice(idx, 1)
  registeredListeners.splice(idx, 1)
}

const listen = (el, events, cb) => {
  if (!el) return
  for (let i = 0, l = events.length; i < l; i += 1) {
    addListener(el, events[i], cb)
  }
  return { elementsWithListeners, registeredListeners }
}

const unlisten = (el, events, cb) => {
  if (!el) return
  for (let i = 0, l = events.length; i < l; i += 1) {
    removeListener(el, events[i], cb)
  }
  return { elementsWithListeners, registeredListeners }
}

const computeCurrentBreakpoint = (theme) => {
  const breakpointKeys = reverse(keys(theme.constants.breakpoints))
  if (!isBrowser()) {
    return breakpointKeys.shift()
  }
  let currentKey
  for (let key of breakpointKeys) {
    currentKey = key
    if (window.innerWidth >= theme.constants.breakpoints[key]) {
      break
    }
  }
  return currentKey
}

const isBrowser = () => (typeof window !== 'undefined')

const weightedPosition = ({ pos, max, easing = 0.5, start = 100 }) => {
  return (pos > start)
    ? (pos > max)
      ? 1
      : (pos > easing)
        ? (pos / max) + ((1 - pos / max) / 2)
        : (pos / max) - ((pos / max) / 2)
    : 0
}

export {
  hexToRgb,
  hexToRgb2,
  findScrollable,
  findStickyChildren,
  listen,
  unlisten,
  computeCurrentBreakpoint,
  isBrowser,
  scrollTo,
  weightedPosition
}

import React, { useEffect, useState } from 'react'
import { useSpring, config } from 'react-spring'
import keys from 'lodash/keys'
import trimStart from 'lodash/trimStart'
import startsWith from 'lodash/startsWith'
import cnx from 'classnames'
import styled, { withTheme, css } from 'styled-components'
import { AnimatedSpan, AnimatedNav } from './MemoMapping'
import { hexToRgb2, listen, unlisten, weightedPosition, scrollTo } from '../utils/ux'
import { graphql, Link, StaticQuery, navigate } from 'gatsby'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { Parallax } from 'react-parallax'

const brandHoverStyles = {
  initial: {
    opacity: 0.8,
    transform: 'scale(1)'
  },
  active: {
    opacity: 1,
    transform: 'scale(1.1)'
  }
}

const navMapping = {
  home: {
    label: 'Facilitas',
    path: '/',
    exact: true
  },
  events: {
    label: 'Events',
    path: '/events'
  },
  vita: {
    label: 'Vita',
    path: '/vita'
  },
  legal: {
    label: 'Legal',
    path: '/legal'
  }
}

const StyledReactParallax = styled(Parallax)`
  min-height: fit-content;
  > img {
    object-fit: cover;
    max-width: unset;
    filter: saturate(1.5) !important;
  }
`

const NavContainer = styled(AnimatedNav)`
  ${({ theme }) => keys(theme.constants.breakpoints).map(bp => css`
    @media (min-width: ${theme.constants.breakpoints[bp]}px) {
      right: ${theme.constants.spacings[theme.components.scrollbar.width][bp]}px !important;
    }
  `)}
`

/* eslint-disable */
const Navigation = React.memo(({ theme, rootRef, location: { pathname, hash = '' } }) => {
  const [burgerToggle, setBurgerToggle] = useState(false)
  const [active, setActive] = useState({ ...navMapping.home, hash: '' })
  const [brandHover, setBrandHover] = useSpring({ ...brandHoverStyles.initial, config: config.gentle })
  const [styleProps, setStyleProps] = useSpring({
    backgroundColor: hexToRgb2(theme.constants.colors.headerBackground, 0),
    config: config.slow
  })
  useEffect(() => {
    const handler = (e) => {
      setStyleProps({
        backgroundColor: hexToRgb2(theme.constants.colors.headerBackground, weightedPosition({ pos: e.target.scrollTop, max: e.target.clientHeight * 0.35, start: 0 })),
        config: config.slow
      })
    }
    if (rootRef.current) {
      scrollTo(rootRef.current, 500, null, true)
      listen(rootRef.current, ['scroll'], handler)
    }
    return () => {
      if (rootRef.current) {
        unlisten(rootRef.current, ['scroll'], handler)
      }
    }
  }, [rootRef])
  useEffect(() => {
    let navItem
    for (let key of keys(navMapping)) {
      if (navMapping[key].exact && pathname === navMapping[key].path) {
        navItem = navMapping[key]
      } else if (!navMapping[key].exact && pathname.includes(navMapping[key].path)) {
        navItem = navMapping[key]
      }
    }
    const current = { ...navItem, hash: trimStart(hash, '#')}
    if (!isEqual(active, current)) {
      setActive(current)
      scrollTo(rootRef.current, 500, null, true)
    }
  }, [pathname, hash])
  return (
    <div>
      <NavContainer style={styleProps} className={`navbar is-fixed-top is-transparent`} role='navigation' aria-label='main navigation'>
        <div className={'container is-widescreen'}>
          <div className='navbar-brand'>

            <a className='navbar-item is-hoverable' onClick={() => {
              navigate(navMapping.home.path)
              scrollTo(rootRef.current, 500, null, true)

            }} onMouseOver={() => setBrandHover(brandHoverStyles.active)} onMouseOut={() => setBrandHover(brandHoverStyles.initial)}>
              <div className='tags has-addons'>
                <AnimatedSpan className='tag is-large has-background-white-bis' style={brandHover}>
                  <img src='/logo.png' alt={''} />
                </AnimatedSpan>
                <span className='tag is-large has-background-dark'>
                  <h4 className='title is-4 has-text-white'>{navMapping.home.label}</h4>
                </span>
              </div>
            </a>
            <a role='button' className={cnx({
              'navbar-burger burger': true,
              'is-active': burgerToggle
            })} aria-label='menu' aria-expanded='false' onClick={() => setBurgerToggle(!burgerToggle)}>
              <span aria-hidden='true' />
              <span aria-hidden='true' />
              <span aria-hidden='true' />
            </a>
          </div>

          <div className={cnx({
            'navbar-menu': true,
            'is-active': burgerToggle
          })} onMouseLeave={() => burgerToggle && setBurgerToggle(false)} style={{ backgroundColor: 'transparent' }}>
            <div className='navbar-end'>
              <div className='tabs is-toggle'>
                <ul style={{ justifyContent: 'center', backgroundColor: 'transparent' }}>
                  <li className={cnx({
                    'is-active':  active.path === navMapping.events.path
                  })}>
                    <Link to={navMapping.events.path}>
                      <span className='icon is-small'><i className='far fa-calendar-alt' aria-hidden='true' /></span>
                      <span>{navMapping.events.label}</span>
                    </Link>
                  </li>
                  <li className={cnx({
                    'is-active':  active.path === navMapping.vita.path
                  })}>
                    <Link to={navMapping.vita.path}>
                      <span>{navMapping.vita.label}</span>
                    </Link>
                  </li>
                  <li className={cnx({
                    'is-active':  active.path === navMapping.legal.path
                  })}>
                    <Link to={navMapping.legal.path}>
                      <span>{navMapping.legal.label}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </NavContainer>
      <StaticQuery query={indexQuery} render={
        ({ pages, welcome}) => {
          let currentImage = get(welcome, `frontmatter.image.childImageSharp.fluid`, {})
          try {
            if (active.hash) {
              currentImage = pages.edges
                .find(({node}) => node.frontmatter.path === active.hash)
                .node.frontmatter.image.childImageSharp.fluid
            } else {
              currentImage = pages.edges
                .find(({node}) => (node.fields.slug === pathname) || (node.frontmatter.path === active.path))
                .node.frontmatter.image.childImageSharp.fluid
            }
          } catch (_) {
            //noop
          }
          return (
            <StyledReactParallax
              parent={rootRef.current}
              bgImage={currentImage.src}
              bgImageSizes={currentImage.sizes}
              bgImageSrcSet={currentImage.srcSet}
              strength={400}
            >
              <div style={{
                width: '100vw',
                height: '40vh',
                borderBottom: '1px solid #0D050E'
              }} />
            </StyledReactParallax>
          )
        }
      } />
    </div>
  )
}, (props, prevProps) => props.location.pathname === prevProps.location.pathname &&
  props.location.hash === prevProps.location.hash
)

export default withTheme(Navigation)

const indexQuery = graphql`
  query {
    welcome: markdownRemark(frontmatter: { path: { eq: "/" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid (maxWidth: 2048) {
              src
              sizes
              srcSet
            }
          }
        }
      }
    }
    pages: allMarkdownRemark {
      edges {
        node {
          html
          fields {
            slug
            excerptHTML
          }
          frontmatter {
            title
            path
            order
            image {
              childImageSharp {
                fluid (maxWidth: 2048) {
                  src
                  sizes
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`

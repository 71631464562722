export default {
  md: {
    borderWidth: 2,
    borderRadius: 2
  },
  lg: {
    borderWidth: 2,
    borderRadius: 2
  },
  xl: {
    borderWidth: 3,
    borderRadius: 3
  },
  xxl: {
    borderWidth: 3,
    borderRadius: 4
  },
  xxxl: {
    borderWidth: 4,
    borderRadius: 5
  }
}

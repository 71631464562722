import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie/lib'
import Navigation from '../components/Navigation'
import LegalConsent from '../components/LegalConsent'
import { Root, RootSegment } from '../components/elements/styled'
import themes from '../themes'
import '../css/index.scss'

const MainLayout = React.memo(({ children, location }) => {
  const [rootRef] = useState(React.createRef())
  return (
    <ThemeProvider theme={themes.base}>
      <CookiesProvider>
        <Root>
          <Helmet titleTemplate={'Facilitas - %s'}>
            <meta http-equiv="Content-Security-Policy" content="default-src * 'unsafe-inline' 'unsafe-eval'" />
          </Helmet>
          <RootSegment ref={rootRef} className={'has-background-light'}>
            <Navigation rootRef={rootRef} location={location} />
            {children}
            <LegalConsent />
          </RootSegment>
        </Root>
      </CookiesProvider>
    </ThemeProvider>

  )
})

export default MainLayout

import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import upperCase from 'lodash/upperCase'
import isRegExp from 'lodash/isRegExp'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import get from 'lodash/get'
import keys from 'lodash/keys'
import hash from 'object-hash'

const findDeepValues = (object, comparator = /^(https?|ftp):\/\/.*(jpe?g|png|gif|bmp)/) => {
  if (!isString(comparator) && !isRegExp(comparator)) {
    throw new Error('Comparator for finding deep values should be string or regexp!')
  }
  let ret = []
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (isObject(object[prop])) {
        ret = ret.concat(findDeepValues(object[prop], comparator))
      } else {
        if (isString(comparator) && isString(object[prop]) && upperCase(object[prop]).includes(upperCase(comparator))) {
          ret.push(object[prop])
        }
        if (isRegExp(comparator) && isString(object[prop]) && comparator.test(object[prop])) {
          ret.push(object[prop])
        }
      }
    }
  }
  return ret
}

const compare = (item1, item2, fields = ['name', 'label', 'id']) => {
  let compare1
  let compare2
  for (let field of fields) {
    if (isFunction(field)) {
      return field(item1, item2)
    }
    const f1 = get(item1, field)
    compare1 = isString(f1) ? upperCase(f1) : f1
    const f2 = get(item2, field)
    compare2 = isString(f2) ? upperCase(f2) : f2
    if (compare1 && compare2) {
      let curr = (compare1 < compare2 ? -1 : (compare1 > compare2 ? +1 : 0))
      if (curr !== 0) {
        return curr
      }
    }
  }
  compare1 = isString(item1) ? upperCase(item1) : item1
  compare2 = isString(item2) ? upperCase(item2) : item2
  return (compare1 < compare2 ? -1 : (compare1 > compare2 ? +1 : 0))
}

const equalLike = (obj1, obj2) => {
  if (isPlainObject(obj1) && isPlainObject(obj2)) {
    const obj1Keys = keys(obj1)
    const obj2Keys = keys(obj2)
    if (obj1Keys.length !== obj2Keys.length) return false
    for (let key of obj1Keys) {
      if (!equalLike(obj1[key], obj2[key])) return false
    }
    return true
  }
  if (isArray(obj1, obj2)) {
    if (obj1.length !== obj2.length) return false
    for (let id = 0; id < obj1.length; id++) {
      if (!equalLike(obj1[id], obj2[id])) return false
    }
    return true
  }
  return obj1 === obj2
}

const DEFAULTHASHOPTIONS = {
  algorithm: 'md5',
  ignoreUnknown: true,
  respectFunctionProperties: false,
  respectType: false
}

const hashDefault = (item) => {
  return hash(item, DEFAULTHASHOPTIONS)
}

const isHashEqual = (item1, item2) => {
  return hashDefault(item1) === hashDefault(item2)
}

export {
  findDeepValues,
  compare,
  equalLike,
  isHashEqual,
  hashDefault,
  DEFAULTHASHOPTIONS
}

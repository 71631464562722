export default {
  s: {
    md: 2,
    lg: 3,
    xl: 3,
    xxl: 4,
    xxxl: 5
  },
  sm: {
    md: 4,
    lg: 5,
    xl: 5,
    xxl: 6,
    xxxl: 7
  },
  md: {
    md: 8,
    lg: 9,
    xl: 10,
    xxl: 11,
    xxxl: 13
  },
  lg: {
    md: 16,
    lg: 18,
    xl: 20,
    xxl: 22,
    xxxl: 26
  },
  xl: {
    md: 24,
    lg: 27,
    xl: 30,
    xxl: 33,
    xxxl: 39
  },
  xxl: {
    md: 32,
    lg: 36,
    xl: 40,
    xxl: 44,
    xxxl: 52
  },
  xxxl: {
    md: 36,
    lg: 40,
    xl: 44,
    xxl: 48,
    xxxl: 52
  }
}

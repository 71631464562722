import colors from '../../constants/colors'
import sizes from './sizes'

export default {
  width: 'md',
  backgroundColor: colors.primary.cp40,
  lineColor: colors.secondary.cs10,
  thumbColor: colors.primary.cp10,
  thumbOpacity: 0.15,
  thumbHoverOpacity: 0.4,
  sizes
}

import breakpoints, { breakpointKeys } from './breakpoints'
import colors from './colors'
import spacings from './spacings'
import fonts from './fonts'

export default {
  breakpointKeys,
  breakpoints,
  colors,
  spacings,
  fonts
}

export const breakpointKeys = [
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl'
]

export default {
  md: 0,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 2000
}

import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { isHashEqual } from '../../utils/data'

const StyledNotification = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
`
/* eslint-disable */
export default React.memo(({ cookies }) => {
  const legalCookie = cookies.get('legalConsent')
  const [show, setShow] = useState(!legalCookie)
  return show
    ? <StyledNotification className={'column is-mobile is-one-quarter m-md'}>
      <div className={'notification is-link'} style={{ border: '1px solid #0D050E' }}>
        <div className={'subtitle'}>
          <span>Diese Seite benutzt Cookies. Mehr Informationen zum Datenschutz finden Sie </span>
          <Link to={'/legal'}>
            <span>hier!</span>
          </Link>
        </div>
        <a className='button is-primary is-inverted is-outlined' onClick={() => {
          setShow(false)
          cookies.set('legalConsent', true, { sameSite: 'strict' })
        }}>Verstanden</a>
      </div>
    </StyledNotification>
    : null
}, (props, prevProps) => isHashEqual(props.cookies, prevProps.cookies))

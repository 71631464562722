import colors from '../../constants/colors'

export default {
  color: colors.primary.cp20,
  opacity: 0.2,
  sizes: {
    md: {
      x: 0,
      y: 2,
      blur: 2,
      spread: '-1'
    },
    lg: {
      x: 0,
      y: 2,
      blur: 2,
      spread: '-1'
    },
    xl: {
      x: 0,
      y: 2,
      blur: 2,
      spread: '-1'
    },
    xxl: {
      x: 0,
      y: 2,
      blur: 2,
      spread: '-1'
    },
    xxxl: {
      x: 0,
      y: 2,
      blur: 2,
      spread: '-1'
    }
  }
}

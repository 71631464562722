export default {
  headerBackground: '#842d3e',
  primary: {
    cp10: '#341218',
    cp15: '#B39197',
    cp20: '#0D050E',
    cp30: '#12341d',
    cp35: '#2b4e36',
    cp40: '#FFFFFF'
  },
  secondary: {
    cs10: '#f8f9fa',
    cs15: '#eef2f5',
    cs20: '#7E7E88',
    cs25: '#2e3b4e',
    cs30: '#006C8E',
    cs40: '#dee2e6'
  },
  tertiary: {
    ct10: '#81B620',
    ct15: '#F8FBF4',
    ct20: '#B62020',
    ct25: '#FBF4F4',
    ct30: '#E89616',
    ct35: '#FEFAF3',
    ct50: '#4F6D81',
    ct55: '#A8B7C1'
  },
  graph: {
    cg10: '#E94E8C',
    cg20: '#B67AFF',
    cg30: '#53CCD5',
    cg40: '#A0DC00',
    cg50: '#FFD200',
    cg60: '#ABABB1'
  },
  transparent: 'transparent'
}

import styled, { css } from 'styled-components'
import keys from 'lodash/keys'
import { hexToRgb2 } from '../../../utils/ux'

export const scrollbarStyles = css`
  ${({ theme, withScrollbarPadding }) => {
    return css`
    ::-webkit-scrollbar-thumb {
      background: ${hexToRgb2(theme.components.scrollbar.thumbColor, theme.components.scrollbar.thumbOpacity)};
      border-left: 4px solid transparent;
      border-right: 2px solid transparent;
      background-clip: content-box;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: ${hexToRgb2(theme.components.scrollbar.thumbColor, theme.components.scrollbar.thumbHoverOpacity)};
      border-left: 4px solid transparent;
      border-right: 2px solid transparent;
      background-clip: content-box;
    }
  
    ${keys(theme.constants.breakpoints).map(bp => css`
      @media (min-width: ${theme.constants.breakpoints[bp]}px) {
        ${withScrollbarPadding && `
          padding-right: ${theme.constants.spacings.md[bp]}px;
          @supports ( -moz-appearance:none ){
            > div {
              width: calc(100% + ${theme.constants.spacings.md[bp]}px);
            }
          }
        `}
        ::-webkit-scrollbar-track {
          background-color: ${theme.components.scrollbar.backgroundColor};
          border-left: solid ${theme.components.scrollbar.sizes[bp].borderWidth}px ${theme.components.scrollbar.lineColor};
        }
        
        ::-webkit-scrollbar {
          width: ${theme.constants.spacings[theme.components.scrollbar.width][bp]}px;
        }
        
        ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
          border-bottom-right-radius: ${theme.components.scrollbar.sizes[bp].borderRadius}px;
          border-top-right-radius: ${theme.components.scrollbar.sizes[bp].borderRadius}px;
          border-bottom-left-radius: ${2 + theme.components.scrollbar.sizes[bp].borderRadius}px;
          border-top-left-radius: ${2 + theme.components.scrollbar.sizes[bp].borderRadius}px;
        }
      }
      `)}
  `
  }}
  `

const RootSegment = styled.div`
    overflow-y: ${({ overflow = 'scroll' }) => overflow};
    overflow-x: hidden;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    background: rgb(255,253,255);
    background-image: linear-gradient(to top, #ffcfd7, #fed9e5, #fee4f0, #fdeff9, #fff9ff);;
    ${scrollbarStyles}
  `

export default RootSegment
